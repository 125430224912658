<template>
  <table-view
    :total='total'
    :query-info.sync='queryInfo'
    @reload-table='renderTable'>
    <template #header>
      <view-search-form
        :query-info='queryInfo'
        :tool-list="['keyword']"
        :show-clear-btn='false'
        style='display: inline-block;'
        @on-search='renderTable(1)' />
      <div class='header-button fr'>
        <el-button type='primary' size='mini' @click="addLecture('create')">发布讲座</el-button>
      </div>
    </template>
    <el-table :data='lectures' border stripe>
      <el-table-column prop='lectureName' width='200' label='主题' />
      <el-table-column prop='address' width='200' label='讲座地点' />
      <el-table-column prop='startTime' width='160' label='开始时间' />
      <el-table-column prop='endTime' width='160' label='结束时间' />
      <el-table-column prop='enrollStartTime' width='160' label='报名开始时间' />
      <el-table-column prop='enrollEndTime' width='160' label='报名结束时间' />
      <el-table-column prop='peopleAmount' label='人数限制' width='100' />
      <el-table-column prop='applyNum' label='已报名人数' width='100' />
      <el-table-column prop='publishUserName' label='发布人' width='120' />
      <el-table-column prop='startUpFlag' label='启用' width='60'>
        <template v-slot='{ row }'>
          <el-tag v-if='row.startUpFlag === 1' type='success'>是</el-tag>
          <el-tag v-else type='warning'>否</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop='remarks' label='备注' width='200' />
      <el-table-column label='操作' fixed='right' width='240'>
        <template v-slot='{ row }'>
          <el-button type='primary' size='small' @click="addLecture('edit', row.id)">编辑</el-button>
          <el-button type='primary' plain size='small' @click='applyList(row.id)'>报名名单</el-button>
          <el-button size='small' @click='del(deleteLecture, row.id, `讲座信息-${row.lectureName}`)'>删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <apply-list :showApplyListForm='showApplyListForm' />
  </table-view>
</template>

<script>
import applyList from './dialog/applyList.vue'
import { deleteLecture, getLectureList } from '@/api/lecture'
import tableView from '@/vue/mixins/table-view'

export default {
  components: {
    applyList
  },
  mixins: [tableView],
  data () {
    return {
      lectures: [],
      showApplyListForm: {
        isOpen: false,
        type: '',
        title: ''
      },
      addData: {
        isOpen: false,
        type: '',
        title: ''
      }
    }
  },
  created () {
    this.renderTable(1)
  },
  methods: {
    deleteLecture, // 删除api
    // 发布讲座
    addLecture (type, id) {
      if (type === 'create') {
        this.$router.push('/lecture/lecturePublishEdit')
      } else {
        this.$router.push({
          path: '/lecture/lecturePublishEdit',
          query: { id }
        })
      }
    },
    // 获取讲座列表
    renderTable (pageNum) {
      this.getTableData(getLectureList, pageNum)
    },
    // 获取报名名单
    applyList (id) {
      this.showApplyListForm.isOpen = true
      this.showApplyListForm.id = id
      this.showApplyListForm.title = '报名名单'
    }
  }
}
</script>
