<template>
  <div>
    <base-dialog
      :model="showApplyListForm.isOpen"
      width="60%"
      maxHeight="60vh"
      marginTop="15vh"
      @close="closeDialogHandle"
      :title="showApplyListForm.title"
    >
      <template #body>
        <el-table :data="applyList" border stripe>
          <el-table-column prop="stuName" label="学生姓名"/>
          <el-table-column prop="className" label="班级名"/>
          <el-table-column prop="collegeName" label="学院"/>
          <el-table-column prop="campusName" label="校区"/>
          <el-table-column label="操作" fixed="right">
            <template v-slot="scoped">
              <el-button
                type="danger"
                size="mini"
                @click="handleDeleteApply(scoped.row.id)"
              >删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <base-pagination
          :total="total"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
        ></base-pagination>
      </template>
      <template #footer>
        <el-button @click="showApplyListForm.isOpen = false">关 闭</el-button>
      </template>
    </base-dialog>
  </div>
</template>
<script>

import { deleteLectureApply, getLectureApplyList } from '@/api/lectureApply'

export default {
  props: {
    showApplyListForm: {
      type: Object
    }
  },
  watch: {
    'showApplyListForm.isOpen': function (isOpen) {
      if (isOpen) {
        this.getLectureApplyList()
      } else {
        this.applyList = []
      }
    }
  },
  data () {
    return {
      applyList: [],
      total: 0
    }
  },
  methods: {
    // 关闭弹窗
    closeDialogHandle () {
      this.showApplyListForm.isOpen = false
    },
    getLectureApplyList () {
      getLectureApplyList({
        lectureId: this.showApplyListForm.id
      }).then(res => {
        this.applyList = res.data.list
        this.total = res.data.total
      })
    },
    handleDeleteApply (id) {
      this.$confirm('此操作将永久删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteLectureApply(id).then((res) => {
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
          this.getLectureApplyList()
        })
      }).catch(() => {
        this.$message({
          type: 'warning',
          message: '已取消删除'
        })
      })
    },
    handleSizeChange (size) {
      this.queryInfo.pageSize = size
      this.getLectureList()
    },
    handleCurrentChange (page) {
      this.queryInfo.pageNum = page
      this.getLectureList()
    }
  }
}
</script>

<style></style>
