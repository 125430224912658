import request from '@/service/request'

// 获取消息通知列表
const getLectureApplyList = (params) => {
  return request.post('/app/lectureApply/page', params)
}

// 添加消息通知
const addLectureApply = (params) => {
  return request.post('/app/lectureApply/save', params)
}

// 删除消息通知
const deleteLectureApply = (params) => {
  return request.post('/app/lectureApply/del/' + params)
}

// 根据id获取消息通知信息
const getLectureApplyById = (params) => {
  return request.post('/app/lectureApply/getById/' + params)
}

export {
  getLectureApplyList,
  addLectureApply,
  deleteLectureApply,
  getLectureApplyById
}
